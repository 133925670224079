import Input from '../../../../../Elementos/Input'
import Boton from '../../../../../Elementos/Boton'
import Opciones from '../../../../../Elementos/Opciones'
import React, {useState, useEffect} from "react";
import * as estilos from './AdministrarProductosCSS'
import {
    obtenerCategorias,
    obtenerDetalleProducto,
    listaDistribuidores
} from '../../../../../ApiSanMiguel/Api'

function AgregarEditarProductos(datos){
    const [categorias,setCategorias]=useState([])
    const [estaProcesando, setestaProcesando] = useState(false)
    const [modal, setModal] = useState(false)

    //detalle de producto
    const [nombreProducto, setNombreProducto] = useState("");
    const [unidadesxPaquete, setUnidadesxPaquete] = useState("");
    const [precioDistribuidor, setPrecioDistribuidor] = useState("");
    const [precioPersonal, setPrecioPersonal] = useState("");
    const [precioPublico, setPrecioPublico] = useState("");
    const [estadoOpcion, setEstadoOpcion] = useState('');
    //fin detalle de producto
    useEffect(() => {
        const ejecucion = async () => {
          console.log(datos.param);
          const respuesta = await obtenerCategorias(); // Esta función debe retornar el resultado que necesitas para la validación
          if(respuesta.SMListaErrores.length === 0){
            setCategorias(respuesta.categorias);
          }
          if(datos.tipo === "editar"){
            if(Object.keys(datos.param["productos"]).length === 1){
                const respuesta2 = await obtenerDetalleProducto(datos.param["codProducto"],"");
                console.log(respuesta2);
                if(respuesta2){
                    setNombreProducto(respuesta2.dscProducto);
                    setUnidadesxPaquete(respuesta2.unidadesPaquete);
                    setPrecioDistribuidor(respuesta2.precioDistribuidor);
                    setPrecioPersonal(respuesta2.precioClienteExclusivo);
                    setPrecioPublico(respuesta2.precioTienda);
                    setEstadoOpcion(respuesta2.activo);
                }
            }
          }
          
        }
        ejecucion();
        
    }, []);

    useEffect(() => {
        if(categorias.length > 0){
            const ejecucion = async () => {
                const elementoSeleccionado = document.getElementById('catProductoList');
                elementoSeleccionado.value = datos.param["codCategoria"];
                if(datos.param["codCategoria"] != 0)
                    
                    elementoSeleccionado.disabled=false;
            }
            ejecucion();
        }
        
    }, [categorias]);
    
    const cambiarEstadoOpcion = (event) => {
        setEstadoOpcion(event.target.value);
        //console.log(event.target.value);
        datos.cambiarEstadoOpcion(event.target.value);
    };
    
    const cambiaCampoClick = event => {
        //console.log(event.currentTarget.id)
        datos.cambiaCampoClick(event);
      }
    const cambiarCategoria=(event)=>{
        datos.cambiarCategoria(parseInt(event));
        //console.log(event)
    }
    return (
        <>
        <div style={estilos.estilosAgregarProducto(datos.esComputadora)}>
        <form
            id='formularioPersona'
            style={estilos.contAgregarEditarProd(datos.esComputadora)}
        >
            <div style={estilos.tituloStyle(datos.esComputadora)}>
                {(() => {
                    
                    if (datos.tipo === "agregar")
                        return ""//"Agregar Producto(s)"
                    else if (datos.tipo === "editar")
                        return ""//"Editar Producto(s)"
                    
                    }
                    )()}
            </div>
            <div style={estilos.categoriaAEProdStyle(datos.esComputadora)}>
                <Opciones
                    valorInicial = {datos.param["codCategoria"] ? "algo":false}
                    id='catProductoList'
                    formPersona='entra'
                    alto='55px'
                    moderno='Categoría de Producto'
                    fontSize='0.9em'
                    opciones={categorias !== null ? categorias : []}
                    clave='codCategoria'
                    valor={['descCategoria']}
                    esComputadora={datos.esComputadora}
                    onClick={cambiarCategoria}
                    //onChange={cambiaCampoClick}
                    //estilos={estiloVacio(docSelec)}
                />
            </div>
            <div style={estilos.nombreAEProdStyle(datos.esComputadora)}>
                <Input
                    id='nombres'
                    moderno={Object.keys(datos.param["productos"]).length === 1 || datos.tipo !=="editar" ? "Nombre de Producto":false}
                    tipo='text'
                    
                    autoComplete
                    deshabilitado = {Object.keys(datos.param["productos"]).length === 1 || datos.tipo !=="editar" ? false:true}
                    onChange={cambiaCampoClick}
                    valorInicial={nombreProducto !== '' ? nombreProducto : null}
                />
            </div>
            <div style={estilos.unidadPorPaqueteAEStyle(datos.esComputadora)}>
                <Input
                    id='unidadesxpaquete'
                    moderno='Unid. X Paquete'
                    tipo='number'
                    autoComplete
                    maxDigitos={5}
                    onChange={cambiaCampoClick}
                    valorInicial={unidadesxPaquete !== '' ? unidadesxPaquete : null}
                />
            </div>
            <div style={estilos.precDistribuidorStyle(datos.esComputadora)}>
                <Input
                    id='precioDistribuidor'
                    moderno='Precio Distribuidor'
                    tipo='number'
                    autoComplete
                    maxDigitos={5}
                    onChange={cambiaCampoClick}
                    valorInicial={precioDistribuidor !== '' ? precioDistribuidor : null}
                />
            </div>
            <div style={estilos.precPersonalStyle(datos.esComputadora)}>
                <Input
                    id='precioPersonal'
                    moderno='Precio Personal'
                    tipo='number'
                    autoComplete
                    maxDigitos={5}
                    onChange={cambiaCampoClick}
                    valorInicial={precioPersonal !== '' ? precioPersonal : null}
                />
            </div>
            <div style={estilos.precPublicoStyle(datos.esComputadora)}>
                <Input
                    id='precioPublico'
                    moderno='Precio Público'
                    tipo='number'
                    autoComplete
                    maxDigitos={5}
                    onChange={cambiaCampoClick}
                    valorInicial={precioPublico !== '' ? precioPublico : null}
                />
            </div>
            <div style={estilos.activoStyle(datos.esComputadora)}>
                <input
                    type="radio"
                    id="S"
                    name="opciones"
                    value="S"
                    checked={estadoOpcion === 'S'}
                    onChange={cambiarEstadoOpcion}
                />
                <label htmlFor="S">Habilitado</label>
                {datos.tipo === "agregar" || (datos.tipo === "editar" && Object.keys(datos.param["productos"]).length === 1) ? <br/>:""}
                <input
                    type="radio"
                    id="N"
                    name="opciones"
                    value="N"
                    checked={estadoOpcion === 'N'}
                    onChange={cambiarEstadoOpcion}
                />
                <label htmlFor="N">Deshabilitado</label><br />
                {datos.tipo==="editar" && Object.keys(datos.param["productos"]).length > 1 ? <><input
                    type="radio"
                    id="NULL"
                    name="opciones"
                    value="NULL"
                    checked={estadoOpcion === 'NULL'}
                    onChange={cambiarEstadoOpcion}
                />
                <label htmlFor="NULL">No Cambiar Estado</label></>
                :""
                }
            </div>
            <div style={{  gridArea: datos.esComputadora ? ' 20 / 2/ 21 / 10':' 20 / 2/ 21 / 10', paddingTop: "1vh"}}>
                <Boton
                    moderno='Confirmar'
                    tipo='submit'
                    color={hexToRGBA('#2D8B0B', 1)}
                    tonalidad='20'
                    borde='5px'
                    margenExterno={datos.esComputadora ? '0.1vw':'0.5vw'}
                    margenInterno={datos.esComputadora ? '0.4vw':'1vw'}
                    estaProcesando={estaProcesando}
                    onClick={(event)=>{
                        event.preventDefault();
                        datos.confirmarClick();
                    }}
                    fontSize={datos.esComputadora ? "1vw":"2.5vw"}
                    width= {datos.esComputadora ? "100%":"100%"}
                    height="4vh"
                />
            </div>
            <div style={{  gridArea: datos.esComputadora ? ' 20 / 12/ 21 / 20':' 20 / 12/ 21 / 20', paddingTop: "1vh"}}>
                <Boton
                    moderno='Cancelar'
                    tipo='submit'
                    color={hexToRGBA('#E12121', 1)}
                    tonalidad='20'
                    borde='5px'
                    margenExterno={datos.esComputadora ? '0.1vw':'0.5vw'}
                    margenInterno={datos.esComputadora ? '0.4vw':'1vw'}                                
                    estaProcesando={estaProcesando}
                    fontSize={datos.esComputadora ? "1vw":"2.5vw"}
                    width= {datos.esComputadora ? "100%":"100%"}
                    height="4vh"
                    onClick={(event)=>{
                        event.preventDefault();
                        datos.cerrarModal();
                    }}
                />
            </div>
        </form>
        </div>
        </>
    )

    function hexToRGBA (hex, opacidad) {
        hex = hex.replace('#', '')
        const r = parseInt(hex.substring(0, 2), 16)
        const g = parseInt(hex.substring(2, 4), 16)
        const b = parseInt(hex.substring(4, 6), 16)
      
        return `rgba(${r}, ${g}, ${b}, ${opacidad})`
      }

    function formatearMonedaSoles(valor) {
    return valor.toLocaleString('es-PE', {
        style: 'currency',
        currency: 'PEN'
    });
    }
}

export default AgregarEditarProductos;