import React, { useState, useEffect } from 'react'

function Opciones (datos) {
  const [isFocused, setIsFocused] = useState(false)
  const [cambioValor, setcambioValor] = useState(false)
  const [valor, setValor] = useState('')

  useEffect(() => {
    const ejecucion = async () => {
      let inicial=datos.valorInicial ? true:false;
      console.log(inicial)
      if(inicial){
        setcambioValor(true);
        setValor("inicial");
        //console.log("hola mundo");
      }
    }
    ejecucion();
    
  }, []);

  const listaContenedorsStyle={
    padding:"0",
    margin:"0",
    //backgroundColor: "green",
    boxSizing: "border-box",
    height: "55px",
    width: "100%",
  }
  const listastyle = {
    display: "inline-block",
    padding: '15px 10px 5px 10px',
    borderRadius: '10px',
    height: datos.alto !== "" ? datos.alto:"100%",
    
    boxSizing: "border-box",
    width: "100%",
    fontSize: "15px",
    overflowY:"auto",
    border: "1px solid #ccc",
    boxShadow: "0 1px 1px rgba(0, 0, 0, 0.1)",
    ...(datos.estilos)
    
  }
  const textoSinvalorStyle = {
    display: "inline-block",
    position:"relative",
    bottom: "32px",
    pointerEvents: "none",
    fontSize: datos.fontSize ? datos.fontSize :datos.esComputadora ? '0.9em':'1.6vh',//"16px",
    color: "#999",
    right: "-5%",
    //transform: "translateX(20%)",
    transition:"0.2s ease-in",
    ...(cambioValor && valor!=="" && {bottom: "40px", 
                        fontSize: datos.esComputadora ? '0.6em':"1.4vh", 
                        color: "#333",
                        bottom: datos.formPersona ? datos.esComputadora ?"60px":"60px":"43px",
                        transition:"0.2s ",})
  }
  const opcionStyle ={
    overflowY:"scroll",  
  }
  const capitalizarPrimeraLetra = ([primerLetra, ...resto]) =>
    primerLetra.toUpperCase() + resto.join('').toLowerCase();
  
  return (
    <>
        <div id='lista' style={listaContenedorsStyle}>
          
          <select id={datos.id ? datos.id:'opcionesGenerico'} 
          style={listastyle}
          onChange={async (event)=>{setcambioValor(true);setValor(event.target.value);await datos.onClick(event.target.value)}}
          
          >
            <option value='' style={opcionStyle}> </option>
            {datos.opciones.map(i=>
                {
                    return <option key={i[datos.clave]} value={i[datos.clave]} style={opcionStyle}>
                                {datos.valor.map(val=>(capitalizeWords(i[val])+' '))}
                            </option>
                    }
                    )
                    }
            
          </select>
          <label id='textosinvalor' style={datos.labelStyle ? datos.labelStyle: textoSinvalorStyle}>
            {datos.moderno}
          </label>
        </div>
    </>
  )
}
function capitalizeWords(str) {
  let words = str.toLowerCase().split(' ');
  let capitalizedWords = words.map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
  return capitalizedWords.join(' ');
}
export default Opciones;
