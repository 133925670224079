import React, { useState, useEffect } from 'react'
import despacho from './../../../Imagenes/Iconos/despacho.png'
import generarPedido from './../../../Imagenes/Iconos/generarPedido.png'
import informacionGeneral from './../../../Imagenes/Iconos/informacionGeneral.png'
import pedidos from './../../../Imagenes/Iconos/pedidos.png'
import stock from './../../../Imagenes/Iconos/stock.png'
import ventaTienda from './../../../Imagenes/Iconos/ventaTienda.png'
import menuOpciones from './../../../Imagenes/Iconos/menuOpciones.png'
import {obtenerCookie} from '../../../ApiSanMiguel/Api'
import Boton from './../../../Elementos/Boton'
import './MenuOpciones.css'


function MenuOpciones (informacionAdicional) {
  const [estaProcesando, setestaProcesando] = useState(false)
  const [esComputadora, setesComputadora] = useState(
    window.innerWidth > window.innerHeight
  )
  const [isHovered, setIsHovered] = useState(false)
  const [idHovered, setIdHovered] = useState('')
  const [diferenciaDimensiones, setDifDim] = useState(0.0)
  const [idSeleccionado, setIdSeleccionado] = useState(null)
  const [rol, setRol] = useState(obtenerCookie("descRol")!==null ? obtenerCookie("descRol").toUpperCase().trim():"")
  const [subMenuHabilitar, setSubMenuHabilitar] = useState(false)
  const [idSubMenuHabilitar, setIdSubMenuHabilitar] = useState(null)

  useEffect(() => {
    const handleResize = () => {
      setesComputadora(window.innerWidth > window.innerHeight)
      if(window.innerWidth > window.innerHeight){
        setDifDim(window.innerWidth-window.innerHeight)
      }else{
        setDifDim(window.innerHeight-window.innerWidth)
      }
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  function hexToRGBA (hex, opacidad) {
    hex = hex.replace('#', '')
    const r = parseInt(hex.substring(0, 2), 16)
    const g = parseInt(hex.substring(2, 4), 16)
    const b = parseInt(hex.substring(4, 6), 16)

    return `rgba(${r}, ${g}, ${b}, ${opacidad})`
  }
  const contenedorOpcionesStyle = {
    display: 'grid',
    gridTemplateRows: esComputadora ? 'repeat(5, 1fr)' : '40px 100%',
    gridTemplateColumns: esComputadora ? 'repeat(1, 1fr) 160px' : 'repeat(10, 1fr)',
    width: esComputadora ? '5vw':'100vw',
    height: esComputadora ? '':'10vh',
    boxSizing: 'content-box',
    alignSelf: 'center',
    justifySelf: 'center'
  }
  const propiedadesAdicionalesIconos = {
    backgroundColor: hexToRGBA('#AD6726', 1.0),
    width: esComputadora ? '5vw' : '100%',
    height: esComputadora ? '100vh' : '10vh',
    textAlign: 'center',
    boxSizing: 'border-box',
    paddingTop: '2vh',
    margin: '0px',
    height: esComputadora ? '15vh':'10vh',
  }
  const estiloHover = {
    ...(isHovered && {
      cursor: 'pointer',
      backgroundColor: hexToRGBA('#DCAD2F', 1.0),
      boxShadow: 'inset 0 0 22px #FFFFFF',
      ...(!esComputadora && { borderBottom: '5px solid #603711' }),
      ...(esComputadora && { borderLeft: '5px solid #603711' })
    })
  }
  const estiloSeleccionado = {
      cursor: 'pointer',
      backgroundColor: hexToRGBA('#DCAD2F', 1.0),
      boxShadow: 'inset 0 0 22px #FFFFFF',
      ...(!esComputadora && { borderBottom: '5px solid #603711' }),
      ...(esComputadora && { borderLeft: '5px solid #603711' })
  }
  const botoMenuContextostyle = {
    backgroundColor: "red",
    gridArea: esComputadora ? '1 / 1 / 4 / 2' : '1 / 1 / 2 / 3',
    ...propiedadesAdicionalesIconos,
  }

  const pedidosstyle = {
    display: rol === "JEFE DE EMPAQUETADO" ? 'none':'inline-block',
    gridArea: esComputadora ? 
              rol==='SUPER ADMINISTRADOR' ? 
                    '4 / 1 / 5 / 2' :'2 / 1 / 4 / 2' 
              : rol==='DISTRIBUIDOR DE PRODUCTOS' || rol==='AYUDANTE DE DISTRIBUIDOR' ? '1 / 6 / 2 / 8': rol==='DESPACHADOR' ? '1 / 1 / 2 / 3':'1 / 5 / 2 / 7',
    ...propiedadesAdicionalesIconos,
    ...(idHovered === 'despachoDePedidos' ? estiloHover : ''),
    ...(idSeleccionado === 'despachoDePedidos' ? estiloSeleccionado: ''),
  }
  const generarPedidostyle = {
    display: rol==='DISTRIBUIDOR DE PRODUCTOS' || rol==='AYUDANTE DE DISTRIBUIDOR' ? 'inline-block':'none',
    gridArea: esComputadora ? '3 / 1 / 4 / 2' : '1 / 4 / 2 / 6',
    height: esComputadora ? '' : '10vh',
    ...propiedadesAdicionalesIconos,
    ...(idHovered === 'generarPedido' ? estiloHover : ''),
    ...(idSeleccionado === 'generarPedido' ? estiloSeleccionado: ''),
  }
  const despachostyle = {
    display: rol==='DISTRIBUIDOR DE PRODUCTOS' || rol==='AYUDANTE DE DISTRIBUIDOR' || rol === "JEFE DE EMPAQUETADO" ? 'none':'inline-block',
    gridArea: esComputadora ? rol==="SUPER ADMINISTRADOR" ? '5 / 1 / 6 / 2':'4 / 1 / 5 / 2' : rol==="SUPER ADMINISTRADOR" ? '1 / 7 / 2 / 9':'1 / 5 / 2 / 7',
    ...propiedadesAdicionalesIconos,
    ...(idHovered === 'registroDeVentas' ? estiloHover : ''),
    ...(idSeleccionado === 'registroDeVentas' ? estiloSeleccionado: ''),

  }
  const ventaTiendastyle = {
    display:  rol==='DISTRIBUIDOR DE PRODUCTOS' || rol==='AYUDANTE DE DISTRIBUIDOR' || rol === "SUPER ADMINISTRADOR" || rol === "JEFE DE EMPAQUETADO" ? 'none':'inline-block',
    gridArea: esComputadora ? '5 / 1 / 6 / 2' : '1 / 7 / 2 / 9',
    ...propiedadesAdicionalesIconos,
    ...(idHovered === 'ventaTienda' ? estiloHover : ''),
    ...(idSeleccionado === 'ventaTienda' ? estiloSeleccionado: ''),

  }
  const stockstyle = {
    display: rol==='DISTRIBUIDOR DE PRODUCTOS' || rol==='AYUDANTE DE DISTRIBUIDOR' ? 'none':'inline-block',
    gridArea: esComputadora ? '6 / 1 / 7 / 2' : '1 / 9 / 2 / 11',
    ...propiedadesAdicionalesIconos,
    ...(idHovered === 'stockProductos' ? estiloHover : ''),
    ...(idSeleccionado === 'stockProductos' ? estiloSeleccionado: ''),

  }
  const iconostyle = {
    width: esComputadora ? '60%':'30%',
  }
  const descripcionIconoStyle = {
    fontWeight: 'bolder',
    fontSize: esComputadora ? '0.8vw':'2vw',
    margin: '0px',
    padding: '0px',
    lineHeight: '0.9'
  }
  const personal={
    width: '20%'
  }
  const informacionGeneralstyle={
    display: (obtenerCookie("descRol") !== null ? obtenerCookie("descRol").toUpperCase().trim() : "" )==='SUPER ADMINISTRADOR' ? 'inline-block':'none',
    gridArea: esComputadora ? '2 / 1 / 4 / 2' : '1 / 1 / 2 / 3',
    ...propiedadesAdicionalesIconos,
    ...(idHovered === 'informacionGeneral' ? estiloHover : ''),
    ...(idSeleccionado === 'informacionGeneral' ? estiloSeleccionado: ''),

  }
  const generarPedidoDistribuidorstyle={
    display: (obtenerCookie("descRol") !== null ? obtenerCookie("descRol").toUpperCase().trim() : "" )==='DESPACHADOR' ? 'inline-block':'none',
    gridArea: esComputadora ? '3 / 1 / 4 / 2' : '1 / 3 / 2 / 5',
    ...propiedadesAdicionalesIconos,
    ...(idHovered === 'generarPedidoDistribuidor' ? estiloHover : ''),
    ...(idSeleccionado === 'generarPedidoDistribuidor' ? estiloSeleccionado: ''),

  }
  const manejadorOpcionClick = async (event) =>{
    if(event.currentTarget.id !== "informacionGeneral"){
      informacionAdicional.establecerOpcion(event.currentTarget.id);
      setIdHovered(event.currentTarget.id)
      setIsHovered(true)
      setIdSeleccionado(event.currentTarget.id)
      setSubMenuHabilitar(false)
    }else{
      if(subMenuHabilitar){
        setSubMenuHabilitar(false)
      }else{
        setSubMenuHabilitar(true)
        setIdSubMenuHabilitar(event.currentTarget.id)
      }
    }
  }
  const subopInfGenStyle={
    display: subMenuHabilitar ? "":"none",
    gridArea: esComputadora ? "2/2/7/3":"1/1/2/4",
    zIndex: "10",
    backgroundColor: "#F1EDE3",
    position: esComputadora ? "":"relative",
    height: esComputadora? "":"20.5vh",
    top: esComputadora ? "":"-20.5vh",
    left: esComputadora ? "":"2px",
    padding: esComputadora ? "1vw":"2vw",
    boxSizing: "border-box",
    borderRadius: esComputadora ? "0 1vw 1vw 0": "2vw 2vw 0 0",
    boxShadow: `inset 0 0 8px ${hexToRGBA("#000000",0.75)}`,
    width: esComputadora ? "10vw":"",
  }
  const labelSupOpStyle={
    color: "black",
    fontWeight: 'bolder',
    fontSize: esComputadora ? '0.7vw':'2vw',
    margin: '0px',
    padding: '0px',
    lineHeight: '0.9'
  }
  const crearPersonaClick= event =>{
    console.log(event.currentTarget.id)
    informacionAdicional.establecerOpcion(event.currentTarget.id);
    setIdSeleccionado(idSubMenuHabilitar)
    setSubMenuHabilitar(false)

  }
  const productosClick= event =>{
    console.log(event.currentTarget.id)
    informacionAdicional.establecerOpcion(event.currentTarget.id);
    setIdSeleccionado(idSubMenuHabilitar)
    setSubMenuHabilitar(false)

  }
  return (
    <>
      <div id='contenedorOpciones' style={contenedorOpcionesStyle}>
        {esComputadora && (
          <div id='botoMenuContexto' style={botoMenuContextostyle}>
            <img src={menuOpciones} alt='Logo' style={personal} />
          </div>
        )}
        <div
          id='despachoDePedidos'
          style={pedidosstyle}
          onMouseEnter={event => {
            setIsHovered(true)
            setIdHovered(event.target.id)
          }}
          onMouseLeave={() => {
            setIsHovered(false)
            setIdHovered('')
          }}
          onClick={manejadorOpcionClick}
        >
          <img src={rol === 'DISTRIBUIDOR DE PRODUCTOS' || rol==='AYUDANTE DE DISTRIBUIDOR' || rol === "SUPER ADMINISTRADOR" ? pedidos:despacho} alt='Logo' style={iconostyle} />
          <p style={descripcionIconoStyle}>{rol === 'DISTRIBUIDOR DE PRODUCTOS' || rol==='AYUDANTE DE DISTRIBUIDOR' || rol === "SUPER ADMINISTRADOR" ? 'Lista de Pedidos':'Despacho de Pedidos'}</p>
        </div>
        <div
          id= 'generarPedido'
          style={generarPedidostyle}
          onMouseEnter={event => {
            setIsHovered(true)
            setIdHovered(event.target.id)
          }}
          onMouseLeave={() => {
            setIsHovered(false)
            setIdHovered('')
          }}
          onClick={manejadorOpcionClick}
        >
          <img src={generarPedido} alt='Logo' style={iconostyle} />
          <p style={descripcionIconoStyle}>Generar Pedido</p>
        </div>
        <div
          id='registroDeVentas'
          style={despachostyle}
          onMouseEnter={event => {
            setIsHovered(true)
            setIdHovered(event.target.id)
          }}
          onMouseLeave={() => {
            setIsHovered(false)
            setIdHovered('')
          }}
          onClick={manejadorOpcionClick}
        >
          <img src={pedidos} alt='Logo' style={iconostyle} />
          <p style={descripcionIconoStyle}>Registro de Ventas</p>
        </div>
        <div
          id='ventaTienda'
          style={ventaTiendastyle}
          onMouseEnter={event => {
            setIsHovered(true)
            setIdHovered(event.target.id)
          }}
          onMouseLeave={() => {
            setIsHovered(false)
            setIdHovered('')
          }}
          onClick={manejadorOpcionClick}
        >
          <img src={ventaTienda} alt='Logo' style={iconostyle} />
          <p style={descripcionIconoStyle}>Venta en Tienda</p>
        </div>
        <div
          id='stockProductos'
          style={stockstyle}
          onMouseEnter={event => {
            setIsHovered(true)
            setIdHovered(event.target.id)
          }}
          onMouseLeave={() => {
            setIsHovered(false)
            setIdHovered('')
          }}
          onClick={manejadorOpcionClick}
        >
          <img src={stock} alt='Logo' style={iconostyle} />
          <p style={descripcionIconoStyle}>Stock de Productos</p>
        </div>

        <div
          id='informacionGeneral'
          style={informacionGeneralstyle}
          onMouseEnter={event => {
            setIsHovered(true)
            setIdHovered(event.target.id)
          }}
          onMouseLeave={() => {
            setIsHovered(false)
            setIdHovered('')
          }}
          onClick={manejadorOpcionClick}
        >
          <img src={informacionGeneral} alt='Logo' style={iconostyle} />
          <p style={descripcionIconoStyle}>Información General</p>
        </div>

        <div
          id='generarPedidoDistribuidor'
          style={generarPedidoDistribuidorstyle}
          onMouseEnter={event => {
            setIsHovered(true)
            setIdHovered(event.target.id)
          }}
          onMouseLeave={() => {
            setIsHovered(false)
            setIdHovered('')
          }}
          onClick={manejadorOpcionClick}
        >
          <img src={generarPedido} alt='Logo' style={iconostyle} />
          <p style={descripcionIconoStyle}>Generar Pedido Distribuidor</p>
        </div>
        <div id="subopInfGen" style={subopInfGenStyle}>
          <div>
            <div style={labelSupOpStyle}>Administración de Personal</div>
            <Boton
                moderno='Personal'
                tipo='submit'
                color={hexToRGBA('#FFFFFF', 0.5)}
                tonalidad='20'
                borde={esComputadora ? '0.4vw':'1vw'}
                margenExterno={esComputadora ? "0.6vw 0.2vw":'1vw 1vw 1vw 1vw'}
                margenInterno={esComputadora ? "0.6vw 0.2vw":"1vw 2vw 1vw 2vw"}
                fontSize={esComputadora ? "1vw":"1.6vh"}
                width = {esComputadora ? "7vw":"24vw"}
                estaProcesando={estaProcesando}
                onClick={crearPersonaClick}
                labelStyle={labelSupOpStyle
                }
                border="1px solid #878787"
                id="crearPersona"
              />
              <div style={labelSupOpStyle}>Administración de Productos</div>

              <Boton
                moderno='Productos'
                tipo='submit'
                color={hexToRGBA('#FFFFFF', 0.5)}
                tonalidad='20'
                borde={esComputadora ? '0.4vw':'1vw'}
                margenExterno={esComputadora ? "0.6vw 0.2vw":'1vw 1vw 1vw 1vw'}
                margenInterno={esComputadora ? "0.6vw 0.2vw":"1vw 2vw 1vw 2vw"}
                fontSize={esComputadora ? "1vw":"1.6vh"}
                width = {esComputadora ? "7vw":"24vw"}
                estaProcesando={estaProcesando}
                onClick={productosClick}
                labelStyle={labelSupOpStyle}
                border="1px solid #878787"
                id="administrarProductos"
              />
            </div>
        </div>
      </div>
    </>
  )
}

export default MenuOpciones
