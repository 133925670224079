import React, {useState, useEffect} from "react";
import Boton from './../../../../../Elementos/Boton';
import agregar from './../../../../../Imagenes/Iconos/agregar.png'
import editar from './../../../../../Imagenes/Iconos/editar.png'
import borrar from './../../../../../Imagenes/Iconos/borrar.png'
import AgregarEditarProductos from "./AgregarEditarProductos";

import {
    listarProductos,
    registrarProducto,
    actualizarDetalleProducto,
    eliminarProducto,
    obtenerCookie,
} from '../../../../../ApiSanMiguel/Api'

import * as estilos from './AdministrarProductosCSS'

function AdministrarProductos(datos){
    const [codUsuario, setCodUsuario] = useState(obtenerCookie("codUsuario"))
    const [productos, setProductos] = useState(null)
    const [modal, setModal] = useState(false)
    const [estaProcesando, setestaProcesando] = useState(false)
    
    const [modoModal,setModoModal] = useState("")
    const [agregarProducto, setAgregarProducto] = useState(false)
    const [editarProducto, setEditarProducto] = useState(false)
    const [eliminarProducto, setEliminarProducto] = useState(false)
    const [paramEditProd, setParamEditProd] = useState({})
    const [paramAgregarProd, setParamAgregarProd] = useState({})
    const [paramEliminarProd, setParamEliminarProd] = useState({})
    const [seleccion, setSeleccion] = useState(false)
    const [seleccionados, setSeleccionados] = useState({});
    const [opHabilitadas, setOpHab] = useState(null);

    const [preConfirmacionEliminar, setpreConfirmacionEliminar] = useState(false);
    const [preConfirmacionEditar, setpreConfirmacionEditar] = useState(false);
    const [preConfirmacionAgregar,setpreConfirmacionAgregar] = useState(false);

    const [catSelec, setCatSelec] = useState(0);
    const [nomProd, setNomProd] = useState("");
    const [unixpaq, setUniXPaq] = useState(0);
    const [precDist, setPrecDist] = useState(0);
    const [precPers, setPrecPers] = useState(0);
    const [precPubl, setPrecPubl] = useState(0);
    const [estActi, setEstActi] = useState("");

    useEffect(() => {
        const ejecucion = async () => {
          const respuesta = await listarProductos(codUsuario); // Esta función debe retornar el resultado que necesitas para la validación
          if(respuesta.SMListaErrores.length === 0){
            setProductos(respuesta);
            console.log(respuesta);
            //console.log(respuesta);
          }
        }
        ejecucion();
        const opciones=document.querySelectorAll(".editarVarios, .eliminarVarios")
        setOpHab(opciones);
        opciones.forEach(function(elemento) {
            elemento.disabled = true;
            elemento.style.cursor = 'not-allowed';
            elemento.style.backgroundColor = hexToRGBA("#CCCCCC",1);
        })
    }, []);
    
    const cambioEstadoEvento = (event) =>{
        //console.log(event.target.id,"=>",event.target.checked);
        //console.log(event.target.parentNode.parentNode);
        const elementoPadreSuperior=event.target.parentNode.parentNode;
        const clave=elementoPadreSuperior.id.toString();
        
        if(event.target.checked){
            //console.log(elementoPadreSuperior);
            elementoPadreSuperior.style.backgroundColor = 'lightblue';
            const elemento = {[clave]: elementoPadreSuperior};
            setSeleccionados({...seleccionados,...elemento})
            
            if(!seleccion){
                setSeleccion(true);
                opHabilitadas.forEach(function(elemento) {
                    elemento.disabled = false;
                    elemento.style.cursor = 'pointer';
                    elemento.style.backgroundColor = hexToRGBA("#ad6726",1);
                });
            }
        }else{
            elementoPadreSuperior.style.backgroundColor = 'transparent';
            //const nuevoArreglo = seleccionados.filter(elemento => elemento.id !== clave);
            let nuevoArreglo = seleccionados;
            delete nuevoArreglo[clave];
            setSeleccionados(nuevoArreglo);
            console.log(nuevoArreglo);

            if(Object.keys(seleccionados).length <= 0){
                setSeleccion(false);

                opHabilitadas.forEach(function(elemento) {
                    elemento.disabled = true;
                    elemento.style.cursor = 'not-allowed';
                    elemento.style.backgroundColor = hexToRGBA("#CCCCCC",1);
                });
            }
        }
    }
    const seleccionarTodosEvento= (event) =>{
        const elementoLanzardorEvento=event.target;
        const elementosCategoria=document.querySelectorAll('.categoria'+elementoLanzardorEvento.id);
        let temp={};
        elementosCategoria.forEach(function(elemento) {
            const clave=elemento.parentNode.parentNode.id.toString();
            
            if(elementoLanzardorEvento.checked){ 
                elemento.checked=true;
                elemento.parentNode.parentNode.style.backgroundColor = 'lightblue';
                
                let elemento1 = {[clave]: elemento.parentNode.parentNode};
                temp={...temp,...elemento1,...seleccionados};
                setSeleccionados(temp);
                setSeleccion(true);
            }else{
                elemento.checked=false;

                elemento.parentNode.parentNode.style.backgroundColor = 'transparent';
                let nuevoArreglo = seleccionados;
                delete nuevoArreglo[clave];
                setSeleccionados(nuevoArreglo);
                temp=seleccionados;
                setSeleccion(false);
            }
        });
        
        if(Object.keys(temp).length > 0){
            opHabilitadas.forEach(function(elemento) {
                elemento.disabled = false;
                elemento.style.cursor = 'pointer';
                elemento.style.backgroundColor = hexToRGBA("#ad6726",1);
            });
        }else{
            opHabilitadas.forEach(function(elemento) {
                elemento.disabled = true;
                elemento.style.cursor = 'not-allowed';
                elemento.style.backgroundColor = hexToRGBA("#CCCCCC",1);
            });
        }
    }

    const clickAgregarProductoEvento=(event) =>{
        const codCategoria=event.currentTarget.parentNode.getAttribute("catprod")
        console.log(codCategoria);
        setModal(true);
        setModoModal("agregar")
        setEditarProducto(false);
        setEliminarProducto(false);
        setAgregarProducto(true);
        setParamAgregarProd({["codCategoria"]:codCategoria,"productos":
                                                    paramEditProd,});
        setParamEliminarProd({});
        setParamEditProd({});
        //console.log(paramAgregarProd);
    }
    const clickEditarProductoEvento=(event) =>{
        
        const prodSeleccionado=event.currentTarget.parentNode;  
        //const codCategoria=prodSeleccionado.getAttribute("catprod"); 
        //["codCategoria"]:codCategoria
        console.log()    
        let parametroEditar= {[prodSeleccionado.id]:prodSeleccionado,}
        console.log(parametroEditar);
        setModal(true);
        setModoModal("editar");
        setEditarProducto(true);
        setAgregarProducto(false);
        setEliminarProducto(false);
        setParamEliminarProd({});
        setParamAgregarProd({});
        setParamEditProd(parametroEditar);
    }
    const clickBorrarProdEvento =(event) =>{
        //console.log(event.currentTarget.parentNode.getAttribute('dsc'));
        const prodSeleccionado=event.currentTarget.parentNode;
        console.log(prodSeleccionado);
        //prodSeleccionado.getAttribute('dsc')
        let parametroEliminar = {[prodSeleccionado.id]:prodSeleccionado}
        console.log(parametroEliminar);
        if(Object.keys(seleccionados).length > 0){
            console.log("entro porque hay seleccionados");
        }else{
            console.log("no hay seleccionados")
        }
        setModal(true);
        setModoModal("eliminar");
        setEditarProducto(false);
        setAgregarProducto(false);
        setEliminarProducto(true);
        setParamEliminarProd(parametroEliminar);
        setParamAgregarProd({});
        setParamEditProd({});
    }
    const clickBorrarProdsEvento =(event) =>{
        //console.log(event.currentTarget.parentNode.getAttribute('dsc'));
        //const prodSeleccionado=event.currentTarget.parentNode;
        //prodSeleccionado.getAttribute('dsc')
        
        if(Object.keys(seleccionados).length > 0){
            console.log("entro porque hay seleccionados");
        }else{
            console.log("no hay seleccionados")
        }
        setModal(true);
        setModoModal("eliminar");
        setEditarProducto(false);
        setAgregarProducto(false);
        setEliminarProducto(true);
        setParamEliminarProd(seleccionados);
        setParamAgregarProd({});
        setParamEditProd({});
    }
    const cerrarModalEvento=() =>{
        setModal(false);
        setpreConfirmacionEditar(false);
        setpreConfirmacionEliminar(false);
        setpreConfirmacionAgregar(false);
    }
    const clickAgregarProdsEvento=() =>{
        setModal(true);
        setModoModal("agregar");
        setEditarProducto(false);
        setAgregarProducto(true);
        setEliminarProducto(false);
        setParamEliminarProd(seleccionados);
        setParamAgregarProd({["codCategoria"]:0,"productos":
                                                    paramEditProd,});
        setParamEliminarProd({});
        setParamEditProd({});
    }
    const clickEditarProdsEvento=() =>{
        console.log("click en editar selecciona de varios productos");
        setModal(true);
        setModoModal("editar");
        setEditarProducto(true);
        setAgregarProducto(false);
        setEliminarProducto(false);
        setParamEliminarProd({});
        setParamAgregarProd({});
        setParamEditProd(seleccionados);
    }

    const ClickConfirmarEliminar=(event)=>{
        setpreConfirmacionEliminar(true);
    }
    const ClickConfirmarEditar=(event)=>{
        setpreConfirmacionEditar(true);
    }
    const ClickConfirmarAgregar=(event)=>{
        setpreConfirmacionAgregar(true);
    }
    const clickPreConfimarEliminarSi=(event)=>{
        setpreConfirmacionEliminar(false);
        setModal(false);
    }
    const clickPreConfimarEliminarNo=(event)=>{
        setpreConfirmacionEliminar(false);
    }
    const clickPreConfirmarEditarSi=(event)=>{
        console.log("entre a preconfirmacion");
        console.log(paramEditProd);
        setpreConfirmacionEditar(false);
        //setModal(false);
        console.log(Object.keys(paramEditProd));
        console.log(precPubl);
        console.log(precPers);
        console.log(precDist);
        console.log(estActi);
        
        /*
        productosIn, 
        precioTiendaIn,
        precioClienteIn,
        precioDistribuidorIn,
        activoIn="N",
        editPTIn="N",
        editPCIn="N",
        editPDIn="N",
        editATIn="N",
        eliminarIn= "N"
        */
        //actualizarDetalleProducto();
    }
    const clickPreConfirmarEditarNo=(event)=>{
        setpreConfirmacionEditar(false);   
    }
    const clickPreConfirmarAgregarSi=(event)=>{
        setpreConfirmacionAgregar(false);
        setModal(false);
    }
    const clickPreConfirmarAgregarNo=(event)=>{
        setpreConfirmacionAgregar(false);  
    }

    const cambiarValores=(event)=>{
        switch (event.currentTarget.id) {
            
            case 'nombres':
                setNomProd(event.currentTarget.value)
                break
            case 'unidadesxpaquete':
                setUniXPaq(event.currentTarget.value)
                break
            case 'precioDistribuidor':
                setPrecDist(event.currentTarget.value)
                break
            case 'precioPersonal':
                setPrecPers(event.currentTarget.value)
                break
            case 'precioPublico':
                setPrecPubl(event.currentTarget.value)
                break
            default:
                console.log('ninguno')
        }
    }
    const cambiarCategoria=(event)=>{
        setCatSelec(event);
    }
    const cambiarEstadoOpcion=(event)=>{
       
        setEstActi(event);
    }
    return (
        <>
        {modal ? <div id="modalStyle" style={estilos.modalStyle(datos.esComputadora)}>
                    
                    <div id="contContenidoModalStyle" style={estilos.contContenidoModalStyle(datos.esComputadora)}>
                        <div id="contenidoModalStyle" style={estilos.contenidoModalStyle(datos.esComputadora,modoModal)}>
                            <div style={{
                                display: "flex",
                                gridArea: datos.esComputadora ? ' 1 / 1 / 2 / 21':' 1 / 1 / 2 / 21',
                                justifySelf: "center",
                                alignItems: "center",
                            }}>
                                
                                {(() => {
                    
                                if (agregarProducto)
                                    return "Agregar Producto(s)"
                                else if (editarProducto)
                                    return "Editar Producto(s)"
                                else if (eliminarProducto)
                                    return "Eliminar Producto(s)"
                                }
                                )()}
                            </div>
                            <div style={estilos.botonCerrarModalStyle(datos.esComputadora)}> 
                                
                                <Boton
                                    moderno='X'
                                    tipo='submit'
                                    color={hexToRGBA('#E12121', 1)}
                                    tonalidad='20'
                                    borde='5px'
                                    margenExterno={datos.esComputadora ? "0vw":'0vw'}
                                    margenInterno={datos.esComputadora ? "0.6vw 0.2vw":"1vw 2vw 1vw 2vw"}
                                    fontSize={datos.esComputadora ? "1vw":"1.6vh"}
                                    width = {datos.esComputadora ? "5vh":"7vw"}
                                    height = {datos.esComputadora ? "5vh":"7vw"}
                                    estaProcesando={estaProcesando}
                                    onClick={cerrarModalEvento}
                                    labelStyle={{marginTop: datos.esComputadora ? "":"30%", fontSize: datos.esComputadora ? "2vh":"3vw"}}
                                />
                            </div>
                            {agregarProducto && 
                                <>
                                {preConfirmacionAgregar && <div style={{ 
                                            color: "black",
                                            background: "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(173,103,38,1) 0%, rgba(255,255,255,1) 100%)",
                                            //background: "linear-gradient(90deg, rgba(173,103,38,1) 0%, rgba(255,255,255,1) 48%, rgba(173,103,38,1) 100%)", 
                                            padding: "5px 5px 5px 5px",
                                            boxSizing: "border-box",
                                            borderTop: "0.1px solid #d9d9d9",
                                            borderBottom: "0.1px solid #d9d9d9",
                                            margin: "5px 0px 5px 0px",
                                            gridArea: datos.esComputadora ? ' 2 / 1/ 3 / 21':' 2 / 1/ 3 / 21', 
                                            display: "flex",  
                                            alignItems: "center",
                                            fontSize: datos.esComputadora ? "1vw":"2vw", 
                                            }}>
                                        ¿Seguro que deseas registrar el producto? 
                                        <button style={{margin: "0px 10px 0px 10px"}} onClick={clickPreConfirmarAgregarSi}>Si</button>
                                        <button onClick={clickPreConfirmarAgregarNo}>No</button>
                                </div>}
                                <div style={estilos.agregarProductoStyle(datos.esComputadora)}>
                                    <AgregarEditarProductos 
                                    esComputadora={datos.esComputadora} 
                                    cerrarModal={cerrarModalEvento}
                                    param={paramAgregarProd}
                                    tipo = "agregar"
                                    preConfirmarClickSi={clickPreConfirmarAgregarSi}
                                    preConfirmarClickNo={clickPreConfirmarAgregarNo}
                                    confirmarClick={ClickConfirmarAgregar}
                                    cambiaCampoClick = {cambiarValores}
                                    cambiarCategoria = {cambiarCategoria}
                                    cambiarEstadoOpcion = {cambiarEstadoOpcion}
                                    />
                                    
                                </div>
                                </>
                            } 
                            {editarProducto && 
                                <>
                                <div style={estilos.editarProductoStyle(datos.esComputadora)}>
                                    {preConfirmacionEditar &&  <div style={{ 
                                            color: "black",
                                            background: "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(173,103,38,1) 0%, rgba(255,255,255,1) 100%)",
                                            //background: "linear-gradient(90deg, rgba(173,103,38,1) 0%, rgba(255,255,255,1) 48%, rgba(173,103,38,1) 100%)", 
                                            padding: "5px 5px 5px 5px",
                                            boxSizing: "border-box",
                                            borderTop: "0.1px solid #d9d9d9",
                                            borderBottom: "0.1px solid #d9d9d9",
                                            margin: "5px 0px 5px 0px",
                                            gridArea: datos.esComputadora ? ' 1 / 1/ 2 / 21':' 1 / 1/ 2 / 21', 
                                            display: "flex",  
                                            alignItems: "center", }}>
                                        ¿Seguro que deseas confirmar los cambios? 
                                        <button style={{margin: "0px 10px 0px 10px"}} onClick={clickPreConfirmarEditarSi}>Si</button>
                                        <button onClick={clickPreConfirmarEditarNo}>No</button>
                                    </div>}
                                    <div style={{  gridArea: datos.esComputadora ? ' 2 / 11 / 15 / 21':' 2 / 1/ 4 / 21', display: "flex", justifyContent: "center",  }}>
                                        
                                        <estilos.ScrollableContainer>
                                            <ul>
                                            {Object.keys(paramEditProd).map((elemento)=>{return <li key={paramEditProd[elemento].id}>{paramEditProd[elemento].getAttribute('dsc')}</li>})}
                                            </ul>
                                        </estilos.ScrollableContainer>
                                        
                                        
                                    </div>
                                    <div style={{  gridArea: datos.esComputadora ? ' 2/ 1/ 16 / 10':' 4/ 1/ 16 / 21', display: "flex", justifyContent: "center", marginTop: "5px" }}>
                                        <AgregarEditarProductos 
                                        esComputadora={datos.esComputadora} 
                                        cerrarModal={cerrarModalEvento}
                                        //param={paramEditProd}
                                        param={
                                            {["codCategoria"]:
                                                (() => {
                                                    if (Object.keys(paramEditProd).length===1)
                                                        return paramEditProd[Object.keys(paramEditProd)[0]].getAttribute("catprod")
                                                    else{
                                                        return ""
                                                    }
                                                })(),
                                                "codProducto":
                                                (() => {
                                                    if (Object.keys(paramEditProd).length===1)
                                                        return paramEditProd[Object.keys(paramEditProd)[0]].id
                                                    else{
                                                        return ""
                                                    }
                                                })(),
                                                "productos":
                                                paramEditProd,
                                            }
                                        }
                                        //param={{}}
                                        tipo = "editar"
                                        preConfirmarClickSi={clickPreConfirmarEditarSi}
                                        preConfirmarClickNo={clickPreConfirmarEditarNo}
                                        confirmarClick={ClickConfirmarEditar}
                                        cambiaCampoClick = {cambiarValores}
                                        cambiarCategoria = {cambiarCategoria}
                                        cambiarEstadoOpcion = {cambiarEstadoOpcion}
                                        />
                                    </div>
                                </div>
                                </>
                            } 
                            {eliminarProducto &&
                                <>
                                <div style={estilos.eliminarProductoStyle(datos.esComputadora)}>
                                    {preConfirmacionEliminar && <div style={{ 
                                            color: "black",
                                            background: "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(173,103,38,1) 0%, rgba(255,255,255,1) 100%)",
                                            //background: "linear-gradient(90deg, rgba(173,103,38,1) 0%, rgba(255,255,255,1) 48%, rgba(173,103,38,1) 100%)", 
                                            padding: "5px 5px 5px 5px",
                                            boxSizing: "border-box",
                                            borderTop: "0.1px solid #d9d9d9",
                                            borderBottom: "0.1px solid #d9d9d9",
                                            marginTop: "5px",
                                            gridArea: datos.esComputadora ? ' 1 / 1/ 2 / 11':' 1 / 1/ 2 / 11', 
                                            display: "flex",  
                                            alignItems: "center", }}>
                                        ¿Seguro que deseas continuar? 
                                        <button style={{margin: "0px 10px 0px 10px"}} onClick={clickPreConfimarEliminarSi}>Si</button>
                                        <button onClick={clickPreConfimarEliminarNo}>No</button>
                                    </div>}
                                    <div style={{  gridArea: datos.esComputadora ? ' 2 / 1/ 3 / 11':' 2 / 1/ 3 / 11', display: "flex",  alignItems: "center"}}>
                                    Se elmininaran los siguientes productos: 
                                    </div>
                                    <div style={{  gridArea: datos.esComputadora ? ' 3 / 1/ 9 / 11':' 3 / 1/ 9 / 11', display: "flex", justifyContent: "center",  }}>
                                        
                                        <estilos.ScrollableContainer>
                                            <ul>
                                            {Object.keys(paramEliminarProd).map((elemento)=>{return <li key={paramEliminarProd[elemento].id}>{paramEliminarProd[elemento].getAttribute('dsc')}</li>})}
                                            </ul>
                                        </estilos.ScrollableContainer>
                                        
                                    </div>
                                    <div style={{  gridArea: datos.esComputadora ? ' 9 / 1/ 11 / 5':' 9 / 1/ 11 / 5', paddingTop: "1vh", display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        <Boton
                                        moderno='Confirmar'
                                        tipo='submit'
                                        color={hexToRGBA('#2D8B0B', 1)}
                                        tonalidad='20'
                                        borde='5px'
                                        margenExterno={datos.esComputadora ? '0.1vw':'0.5vw'}
                                        margenInterno={datos.esComputadora ? '0.4vw':'1vw'}
                                        estaProcesando={estaProcesando}
                                        onClick={ClickConfirmarEliminar}
                                        fontSize={datos.esComputadora ? "1vw":"2.5vw"}
                                        width= {datos.esComputadora ? "100%":"100%"}
                                        height="4vh"
                                        />
                                    </div>
                                    <div style={{  gridArea: datos.esComputadora ? ' 9 / 7/ 11 / 11':' 9 / 7/ 11 / 11', paddingTop: "1vh", display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        <Boton
                                        moderno='Cancelar'
                                        tipo='submit'
                                        color={hexToRGBA('#E12121', 1)}
                                        tonalidad='20'
                                        borde='5px'
                                        margenExterno={datos.esComputadora ? '0.1vw':'0.5vw'}
                                        margenInterno={datos.esComputadora ? '0.4vw':'1vw'}                                
                                        estaProcesando={estaProcesando}
                                        fontSize={datos.esComputadora ? "1vw":"2.5vw"}
                                        width= {datos.esComputadora ? "100%":"100%"}
                                        height="4vh"
                                        onClick={()=>{setModal(false);setpreConfirmacionEliminar(false);}}
                                        />
                                    </div>
                                </div>
                                </>
                            } 

                        </div>
                    </div>
                </div>: ""
        }
        <div id='contenedorPrincipal' style={estilos.contenedorPrincipalListaStyle(datos.esComputadora)}>
            <div id='contenedorListado' style={estilos.contenedorLista(datos.esComputadora)}>
                <div id="contenido" style={estilos.contenidoStyle(datos.esComputadora)}>
                    {productos !== null && productos.SMProductosPorCategoria.map(catProducto => {
                        return <div
                                    key={"contenedor"+catProducto.codigoCategoria}
                                    id={"contenedor"+catProducto.codigoCategoria}
                                    style={estilos.cardCategoria(datos.esComputadora)}
                                >
                                
                                <div id={"categoria"+catProducto.codigoCategoria} catprod={catProducto.codigoCategoria} style={estilos.nomCatProdStyle(datos.esComputadora)}>
                                    <div style={{display: "flex",justifyContent: "center", alignItems: "center", marginLeft: "50px" }}>
                                        <input 
                                        id={catProducto.codigoCategoria} 
                                        type="checkbox" 
                                        style={estilos.checkboxStyle(datos.esComputadora)}
                                        onChange={seleccionarTodosEvento}
                                        ></input>
                                        <span style={{fontWeight: "100", fontSize: datos.esComputadora ? "1vw":"2.3vw",}}>Seleccionar Todos</span>
                                    </div>
                                    <div style={{display: "flex",justifyContent: "center", alignItems: "center", fontSize: datos.esComputadora ? "1vw":"2.5vw",}}>{catProducto.categoria}</div>
                                    <div 
                                        style={{display: "flex", backgroundColor: "rgb(45, 139, 11)", justifyContent: "center", alignItems: "center",borderRadius: "15px",cursor: "pointer",  filter: "drop-shadow(0.5px 1px 2px rgba(1, 1, 1, 0.5))",}}
                                        onClick={clickAgregarProductoEvento}
                                    >
                                        <img src={agregar} style={estilos.imagenBotonAgregarStyle(datos.esComputadora)}/>
                                        {datos.esComputadora ? 'Agregar':''}
                                    </div>
                                </div>
                                <div id="contenidoProductosCategoria" style={estilos.contenidoProductosCategoriaStyle(datos.esComputadora)}>
                                    <div id="contenidoInternoProdCategoria" style={estilos.contIntCat(datos.esComputadora)}>
                                        <div id="cabecera" style={estilos.cabProductosStyle(datos.esComputadora)}>
                                            <div style={estilos.checkboxStyle(datos.esComputadora)}>
                                            </div>
                                            <div style={estilos.nombreProductoStyle(datos.esComputadora)}>Nombre Producto</div>
                                            <div style={estilos.unidadPaqueteStyle(datos.esComputadora)}>Unidades X paquete</div>
                                            <div style={estilos.precioDistribuidorStyle(datos.esComputadora)}>Precio para Distribuidor</div>
                                            <div style={estilos.precioEmpleadosStyle(datos.esComputadora)}>Precio para Personal</div>
                                            <div style={estilos.precioPublicoStyle(datos.esComputadora)}>Precio para Público</div>
                                            <div style={estilos.stockActualStyle(datos.esComputadora)}>Stock Actual</div>
                                            <div style={estilos.contenedorBotonEditarStyle(datos.esComputadora)}>Editar</div>
                                            <div style={estilos.contenedorBotonEliminarStyle(datos.esComputadora)}>Eliminar</div>
                                        </div>
                                        {catProducto.SMListaProductos.map(producto => {
                                            return <div id={""+producto.codProducto} key={"producto"+producto.codProducto} catprod={catProducto.codigoCategoria} dsc={capitalizeWords(producto.dscProducto)} style={estilos.contProductoStyle(datos.esComputadora)}>
                                                    <div style={estilos.contenedorCheckboxStyle(datos.esComputadora)}>
                                                        <input 
                                                        id={catProducto.codigoCategoria+"-"+producto.codProducto}
                                                        className={"categoria"+catProducto.codigoCategoria+" "+"producto"} 
                                                        type="checkbox" style={estilos.checkboxStyle(datos.esComputadora)}
                                                        // Estado controlado del checkbox
                                                        onChange={cambioEstadoEvento}
                                                        ></input>
                                                    </div>
                                                    <div style={estilos.nombreProductoStyle(datos.esComputadora)}> {capitalizeWords(producto.dscProducto)} </div>
                                                    <div style={estilos.unidadPaqueteStyle(datos.esComputadora)}>{producto.unidadesPaquete}</div>
                                                    <div style={estilos.precioDistribuidorStyle(datos.esComputadora)}>{formatearMonedaSoles(producto.precioDistribuidor)}</div>
                                                    <div style={estilos.precioEmpleadosStyle(datos.esComputadora)}>{formatearMonedaSoles(producto.precioPersonal)}</div>
                                                    <div style={estilos.precioPublicoStyle(datos.esComputadora)}>{formatearMonedaSoles(producto.precioPublico)}</div>
                                                    <div style={estilos.stockActualStyle(datos.esComputadora)}>{producto.stock}</div>
                                                    <div 
                                                    style={estilos.contenedorBotonEditarStyle(datos.esComputadora)}
                                                    onClick={clickEditarProductoEvento}
                                                    >
                                                        <button style={estilos.botonEditarStyle(datos.esComputadora)}>
                                                            <img src={editar} style={estilos.imagenBotonEditarStyle(datos.esComputadora)}/>
                                                        </button>
                                                    </div>
                                                    <div 
                                                    style={estilos.contenedorBotonEliminarStyle(datos.esComputadora)}
                                                    onClick={clickBorrarProdEvento}
                                                    >
                                                        <button style={estilos.botonEliminarStyle(datos.esComputadora)}>
                                                            <img src={borrar} style={estilos.imagenBotonEliminarStyle(datos.esComputadora)}/>
                                                        </button>
                                                    </div>                                            
                                                    
                                                </div>
                                        })}
                                    </div>
                                </div>
                                
                            </div>
                    })}
                </div>
               
            </div>
        </div>
        <div id="opcionesSeleccionados" style={estilos.opcionesSeleccionados(datos.esComputadora)}>
            <div 
            style={estilos.opcion(datos.esComputadora)}
            onClick={clickAgregarProdsEvento}
            >
                <button style={estilos.botonOpcion(datos.esComputadora)}>Agregar Producto</button>
            </div>
            <div 
            style={estilos.opcion(datos.esComputadora)}
            onClick={clickEditarProdsEvento}
            >
                <button style={estilos.botonOpcion(datos.esComputadora)} className="editarVarios">Editar</button>
            </div>
            <div 
            onClick={clickBorrarProdsEvento}
            >
                <button style={estilos.botonOpcion(datos.esComputadora)} className="eliminarVarios">Eliminar</button>
            </div>
        </div>
        </>
    )

    function capitalizeWords(str) {
        let words = str.toLowerCase().split(' ');
        let capitalizedWords = words.map(word => {
          return word.charAt(0).toUpperCase() + word.slice(1);
        });
        return capitalizedWords.join(' ');
    }
    function formatearMonedaSoles(valor) {
        return valor.toLocaleString('es-PE', {
            style: 'currency',
            currency: 'PEN'
        });
    }
    function hexToRGBA (hex, opacidad) {
        hex = hex.replace('#', '')
        const r = parseInt(hex.substring(0, 2), 16)
        const g = parseInt(hex.substring(2, 4), 16)
        const b = parseInt(hex.substring(4, 6), 16)
      
        return `rgba(${r}, ${g}, ${b}, ${opacidad})`
      }
}
export default AdministrarProductos;
