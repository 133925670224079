import React, { useState, useEffect } from 'react'
import './InicioSesion.css'
import Input from './../../Elementos/Input'
import Boton from './../../Elementos/Boton'
import LogoSanMiguel from './../../Imagenes/LogoSanMiguel.png'
import { iniciarSesion, validarSesion } from '../../ApiSanMiguel/Api'
import { useNavigate } from 'react-router-dom';

function hexToRGBA (hex, opacidad) {
  hex = hex.replace('#', '')
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  return `rgba(${r}, ${g}, ${b}, ${opacidad})`
}

function InicioSesion () {
  const [isWidthLarger, setIsWidthLarger] = useState(
    window.innerWidth > window.innerHeight
  )
  const [estaProcesando, setestaProcesando] = useState(false)
  const [usuario, setUsuario] = useState('')
  const [contrasenia, setContrasenia] = useState('')
  const [usuarioVacio,setUsuarioVacio] = useState(false)
  const [contraseniaVacio,setContraseniaVacio] = useState(false)
  const [errores,seterrores] = useState(null)
  const [hayError, setHayError] = useState(false)
  const navigate=useNavigate();

  useEffect(() => {
    const validar = async () => {
      const respuesta = await validarSesion(); // Esta función debe retornar el resultado que necesitas para la validación
      if(respuesta.SMListaErrores.length ===0 && respuesta.estado === "activo"){
        navigate('/principal')
      }
    }
    validar();
  }, []);


  useEffect(() => {
    
    const handleResize = () => {
      setIsWidthLarger(window.innerWidth > window.innerHeight)
    }
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  const estiloEslogan = {
    display: 'flex',
    color: hexToRGBA('#EE1D23', 1.0),
    fontFamily: "'Carattere', cursive",
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: isWidthLarger ? '2.9vw' : '6vw',
    textShadow:
      '2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff,1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff',
    marginTop: '-0.7em'
  }

  const estiloContenedor = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    justifyContent: 'space-between', // Espacio entre los elementos
    alignItems: 'center',
    //background: "green",
    fontSize: '18px',
    width: '70%', // Ancho del 70% del body
    height: '50%' // Altura del 50% del body
  }

  const estiloContenedorLogin = {
    background: hexToRGBA('#DCAD2F', 0.9),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center', // Centra verticalmente
    alignItems: 'center', // Centra horizontalmente
    width: 'calc(100% - 10px)', // Mitad del ancho menos el espacio entre elementos
    height: 'calc(100%)',
    padding: '50px',
    boxSizing: 'border-box', // El tamaño incluye el relleno y el borde, pero no el margen
    borderRadius: isWidthLarger ? '10px 0px 0px 10px' : '0px 00px 10px 10px'
  }

  const estiloContenedorLogo = {
    background: hexToRGBA('#908A78', 0.75),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center', // Centra verticalmente
    alignItems: 'center', // Centra horizontalmente
    width: 'calc(100% - 10px)', // Mitad del ancho menos el espacio entre elementos
    height: 'calc(100%)',
    padding: '10px',
    boxSizing: 'border-box', // El tamaño incluye el relleno y el borde, pero no el margen
    boxShadow: '4px 4px 46.6px ' + hexToRGBA('#000000', 0.75), // Sombra con desplazamiento x=0, y=0, blur=10px, color rgba(0, 0, 0, 0.2)
    borderRadius: isWidthLarger ? '0px 10px 10px 0px' : '10px 10px 0px 0px'
  }

  const estiloCabecera = {
    color: hexToRGBA('#603711', 1.0),
    fontSize: isWidthLarger ? '3.5vw' : '6vw', // Tamaño de fuente relativo al ancho de la ventana
    fontFamily: 'Pridi, serif', // Cambia "Arial, sans-serif" por la fuente que desees
    fontWeight: 'bold', // Puedes usar "bold", "normal", "lighter", "bolder", etc.
    textAlign: 'center', // Centra el texto horizontalmente
    display: 'flex',
    justifyContent: 'center', // Centra el contenido horizontalmente
    alignItems: 'center' // Centra el contenido verticalmente
  }

  const contieneEslogan = {
    //background :"black",
  }
  
  const estilosError = {
    color:"black",
    fontSize: '12px',
    fontWeight: 'bold',
    display: 'block',
    textAlign: 'center',
    width: '100%',
    padding: '10px',
    boxSizing: 'border-box',
    margin: '5px',
    backgroundColor: '#f96969',
    fontFamily: 'Arial, serif', // Cambia "Arial, sans-serif" por la fuente que desees
  }
  const estiloUsuariovacio={
    //borderColor: usuarioVacio ? 'red':hexToRGBA("#ffffff",0),
    border: usuarioVacio ? '1px solid red':'1px solid #ccc',
    boxShadow: usuarioVacio ? 'inset 0 0 5px red': 'inset 0 0 1px gray'
  }
  const estiloContraseniaVacio={
    //borderColor: contraseniaVacio ? 'red':hexToRGBA("#ffffff",0),
    border: contraseniaVacio ? '1px solid red':'1px solid #ccc',
    boxShadow: contraseniaVacio ? 'inset 0 0 5px red': 'inset 0 0 1px gray'
  }
  const cambioUsuario = event => {
    setUsuario(event.target.value);
    setUsuarioVacio(false);
  }

  const cambioContrasenia = event => {
    setContrasenia(event.target.value)
    setContraseniaVacio(false);
  }
  const manejadorBotonClick = async event => {
    event.preventDefault()
    
    //console.log(usuario.toUpperCase(), contrasenia)
    if(usuario===""){
      setUsuarioVacio(true);
      setHayError(true);
      seterrores({SMListaErrores:[{codError:1,descripcionError:"⚠️No puede haber campos vacíos."}]})

    }
    if(contrasenia===""){
      setContraseniaVacio(true);
      setHayError(true);
      seterrores({SMListaErrores:[{codError:1,descripcionError:"⚠️No puede haber campos vacíos."}]})
    }
    if(usuario !== "" && contrasenia !== ""){
      setestaProcesando(true)
      setHayError(false);
      const respuesta = await iniciarSesion(usuario.trim(), contrasenia.trim())
      setestaProcesando(false)
      
      if(respuesta.SMListaErrores.length !== 0){
        setHayError(true)
        seterrores(respuesta)
      }else{
        setHayError(false)
        navigate('/principal')
      }
    }
  }
  return (
    <div
      id='dispositivoContenedor'
      style={{ height: '100vh', position: 'relative' }}
    >
      <div
        id='contenedor'
        style={{
          ...estiloContenedor,
          flexDirection: isWidthLarger ? 'row' : 'column-reverse'
        }}
      >
        <div id='contenedorLogin' style={estiloContenedorLogin}>
          <div style={estiloCabecera}>Iniciar Sesión</div>
            <form style={{width:'100%'}}>
            <Input
              id='codigoUsuario'
              moderno='Código de Usuario'
              tipo='text'
              estilos={estiloUsuariovacio}
              onChange={cambioUsuario}
              valorInicial = {null}
            />
            <Input
              id='contrasenia'
              moderno='Contraseña'
              tipo='password'
              autoComplete
              estilos={estiloContraseniaVacio}
              onChange={cambioContrasenia}
              valorInicial = {null}
            />
            <Boton
              moderno='Ingresar'
              tipo='submit'
              color={hexToRGBA('#2D8B0B', 1)}
              tonalidad='20'
              borde='5px'
              margenExterno='5px 5px 5px 5px'
              margenInterno='10px'
              estaProcesando={estaProcesando}
              onClick={manejadorBotonClick}
            />
            {hayError ? errores.SMListaErrores.map(i=>{return <p style={estilosError} key={i.codError}>{i.descripcionError}</p>}):''}
            </form>
        </div>
        <div id='contenedorLogo' style={estiloContenedorLogo}>
          <img
            src={LogoSanMiguel}
            alt='Logo'
            style={{ width: '100%', height: 'auto' }}
          />
          <div id='contieneEslogan' style={contieneEslogan}>
            <div id='eslogan' style={estiloEslogan}>
              Un gustito que te Bendice
            </div>
          </div>
        </div>
      </div>
      
    </div>
  )
}

export default InicioSesion
