import styled from 'styled-components';

export const contenedorPrincipalListaStyle = (esComputadora) =>({
    display: 'block',
    gridArea: esComputadora ? '1 / 1 / 21 / 21' : '1 / 1 / 20 / 21',
    width: "100%",
    height: esComputadora ? "70vh":"60vh",
});

export const contenedorLista = (esComputadora) =>({
    //backgroundColor: hexToRGBA("#EEEEEE",1),    
    display: 'grid',
    gridTemplateRows: 'repeat(1, 1fr)',
    gridTemplateColumns: 'repeat(1, 1fr)',
    width: "100%",
    height: "100%",
    borderRadius: "10px",
    overflow: "auto",

 });

export const contenidoStyle=(esComputadora) => ({
    //padding: "5px",
    width: "100%",
});

export const cardCategoria = (esComputadora) =>({
    backgroundColor: 'white', //"#FCFCFC",
    display: 'block',
    //clear: "both",
    borderRadius: '20px',
    margin: '20px 0 0 0',
    //padding: "10px",
    overflow: esComputadora ? 'auto' : 'none',
    height: esComputadora ? 'auto' : 'auto',
    boxSizing: 'border-box',
    width: esComputadora ? '100%' : '83vw',
    border: '1px solid #ccc',
    boxShadow: '0 2px 2px rgba(0, 0, 0, 0.1)',
    

  });
  export const nomCatProdStyle = (esComputadora) => ({
    display: 'grid',
    gridTemplateRows: "100%",
    gridTemplateColumns: "10% 80% 10%",
    backgroundColor: '#AD6726',
    color: 'white',
    fontSize: esComputadora ? '1vw' : '1.7vh',
    //color: "#363636",
    fontWeight: 'bolder',
    //marginBottom: "5px",
    textAlign: 'Center',
    borderRadius: '10px 10px 0px 0px',
    padding: '5px',
  });

  export const contenidoProductosCategoriaStyle = (esComputadora) => ({
    overflow: "auto",
    height: esComputadora ? 'auto' : 'auto',
    //backgroundColor: hexToRGBA("#B98787",0.25),
    //paddingLeft: "10px",
   // zIndex: "80"
    //width: esComputadora ? "":"99%"
  })
  export const contIntCat = (esComputadora) => ({
    //overflow: "auto",
    height: esComputadora ? 'auto' : 'auto',
    //backgroundColor: hexToRGBA("#B98787",0.25),
    //paddingLeft: "10px",
   // zIndex: "80"
    width: esComputadora ? "":"200%"
  })

  export const cabProductosStyle= (esComputadora) => ({
    color: "black",
    display: 'grid',
    gridTemplateColumns: esComputadora ? '5% 20% repeat(7, calc(75%/7))':'5% 15% repeat(7, calc(80%/7))',
    //gridTemplateColumns: '10% 25% repeat(7,1fr)',
    gridTemplateRows: '100%',
    marginTop: '5px',
    fontSize: esComputadora ? '0.9vw' : '1.6vh',
    fontWeight: "900",
    textAlign: "center",
  });

  export const contProductoStyle = (esComputadora)=>({
    display: 'grid',
    gridTemplateColumns: esComputadora ? '5% 20% repeat(7, calc(75%/7))':'5% 15% repeat(7, calc(80%/7))',
    gridTemplateRows: '100%',
    marginTop: '5px',
    fontSize: esComputadora ? '0.9vw' : '1.6vh',
    color: '#868686',
    textAlign: "center",
    paddingLeft:"20px",
});

export const contenedorCheckboxStyle = (esComputadora)=>({
  gridArea: "1/1/2/2",
  //overflow: "auto",
  textAlign: "left",
  marginRight: esComputadora ? "": "10px",
  display: "flex",
  justifyContent: "center", alignItems: "center",
  
});
export const nombreProductoStyle = (esComputadora)=>({
  gridArea: "1/2/2/3",
  overflow: "auto",
  textAlign: "left",
  marginRight: esComputadora ? "": "10px",
  display: "flex",
  justifyContent: "left", alignItems: "center",
  fontSize: esComputadora ? "1vw":"2.3vw",

});

export const unidadPaqueteStyle = (esComputadora)=>({
  gridArea: "1/3/2/4",
  marginRight: esComputadora ? "": "10px",
  display: "flex",
  justifyContent: "center", alignItems: "center",
  fontSize: esComputadora ? "1vw":"2.3vw",

});
export const precioDistribuidorStyle = (esComputadora)=>({
  gridArea: "1/4/2/5",
  marginRight: esComputadora ? "": "10px",
  display: "flex",
  justifyContent: "center", alignItems: "center",
  fontSize: esComputadora ? "1vw":"2.3vw",

});
export const precioEmpleadosStyle = (esComputadora)=>({
  gridArea: "1/5/2/6",
  marginRight: esComputadora ? "": "10px",
  display: "flex",
  justifyContent: "center", alignItems: "center",
  fontSize: esComputadora ? "1vw":"2.3vw",

});
export const precioPublicoStyle = (esComputadora)=>({
  gridArea: "1/6/2/7",
  marginRight: esComputadora ? "": "10px",
  display: "flex",
  justifyContent: "center", alignItems: "center",
  fontSize: esComputadora ? "1vw":"2.3vw",

});
export const stockActualStyle = (esComputadora)=>({
  gridArea: "1/7/2/8",
  marginRight: esComputadora ? "": "10px",
  display: "flex",
  justifyContent: "center", alignItems: "center",
  fontSize: esComputadora ? "1vw":"2.3vw",

});
export const contenedorBotonEditarStyle = (esComputadora)=>({
  gridArea: "1/8/2/9",
  marginRight: esComputadora ? "": "10px",
  display: "flex",
  justifyContent: "center", alignItems: "center",
  
});
export const contenedorBotonEliminarStyle = (esComputadora)=>({
  gridArea: "1/9/2/10",
  marginRight: esComputadora ? "": "10px",
  display: "flex",
  justifyContent: "center", alignItems: "center",
});

export const botonEditarStyle= (esComputadora) => ({
  border: "none",
  backgroundColor: "transparent",
  cursor: "pointer",
  filter: "drop-shadow(0.5px 1px 2px rgba(1, 1, 1, 0.5))",

});
export const botonEliminarStyle= (esComputadora) => ({
  border: "none",
  backgroundColor: "transparent",
  cursor: "pointer",
  filter: "drop-shadow(0.5px 0.5px 0.5px rgba(1, 1, 1, 0.9))",
});

export const imagenBotonEditarStyle= (esComputadora) => ({
  width: esComputadora ? '1.5vw':'2.3vh', 
  height: 'auto'
});
export const imagenBotonEliminarStyle= (esComputadora) => ({
  width: esComputadora ? '1.5vw':'2.6vh', 
  height: 'auto'
});
export const imagenBotonAgregarStyle= (esComputadora) => ({
  width: esComputadora ? '2vw':'4vh', 
  height: 'auto',
  //backgroundColor: "rgb(210, 116, 244)",
  //filter: "",
  borderRadius: "5px",
  cursor: "pointer",
  filter: "invert(100%) drop-shadow(1px 1px 0.5px rgba(1, 1, 1, 0.5))",
});

export const checkboxStyle = (esComputadora)=>({
  //gridArea: "1/1/2/2",
  //overflow: "auto",
  width: esComputadora ? '1.5vw':'2.3vh', 
});

export const modalStyle= (esComputadora) =>({
  position: "absolute",
  left: "0px",
  top: "0px",
  width: "100vw",
  height: "100vh",
  backgroundColor: hexToRGBA("#C1C1C1",0.8),
  zIndex: "20", 
  boxSizing: "border-box"

});

export const contContenidoModalStyle=(esComputadora) => ({
  position: "relative",
  width: "90vw",
  height: "90vh",
  left: "5vw",
  boxSizing: "border-box",
  top: "5vh",
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
export const contenidoModalStyle=(esComputadora,modo) =>({
  display: 'grid',
  gridTemplateRows: 'repeat(20, 1fr)',
  gridTemplateColumns: 'repeat(20, 1fr)',
  //width: modo=="eliminar" ? esComputadora ? "30vw":"60vw":"80vw",
  width: (() => {
   
   
  })(),
  height: modo=="eliminar" ? "40vh":"70vh",
  overflow: "auto",
  backgroundColor: "white",
  boxSizing: "border-box",
  borderRadius: "10px",
  padding: "10px"
});

export const botonCerrarModalStyle=(esComputadora) =>({
  display: "flex",
  gridArea: esComputadora ? ' 1 / 18 / 2 / 21':' 1 / 18 / 2 / 21',
  justifySelf: "right",
  alignItems: "center",
});

export const agregarProductoStyle=(esComputadora)=>({
  gridArea: esComputadora ? ' 3 / 1/ 21 / 21':' 3 / 1/ 21 / 21',
  display: "grid",
  gridTemplateRows : "repeat(1,1fr)",
  gridTemplateColumns: "repeat(1,1fr)",
  //backgroundColor: "skyblue",
})
export const editarProductoStyle=(esComputadora)=>({
  gridArea: esComputadora ? ' 2 / 1/ 21 / 21':' 2 / 1/ 21 / 21',
  //backgroundColor: "green",
  display: "grid",
  gridTemplateRows : "repeat(15,1fr)",
  gridTemplateColumns: "repeat(20,1fr)",
  fontSize: esComputadora ? "1vw":"2.5vw",
})
export const eliminarProductoStyle=(esComputadora)=>({
  gridArea: esComputadora ? ' 2 / 1/ 21 / 21':' 2 / 1/ 21 / 21',
  //backgroundColor: "red",
  display: "grid",
  gridTemplateColumns : "repeat(10,1fr)",
  gridTemplateRows : "repeat(10,1fr)",
  fontSize: esComputadora ? "1vw":"2.5vw",
});

export const opcionesSeleccionados = (esComputadora) =>({
  backgroundColor: hexToRGBA("#ad6726",0.2),
  borderRadius: "10px",
  width: "100%",
  height: "10vh",
  gridArea: "20/1/21/21",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  
});

export const opcion = (esComputadora) => ({
  marginRight: "5vw",
})
export const botonOpcion = (esComputadora) =>({
  backgroundColor: hexToRGBA("#ad6726",1),
  border: "0px solid #000",
  borderRadius: "1vh",
  padding: esComputadora ? "1vw":"2vw",
  color: "white",
  fontSize: esComputadora ? "1vw":"2.3vw",
  cursor: "pointer",
  
});

export const ScrollableContainer = styled.div`
  width: 100%;
  background-color: #f0f0f0; /* Fondo del contenedor */
  overflow: auto;
  height: 100%;
  border-radius: 8px 8px 8px 8px; /* Bordes redondeados */
  padding: 0px 10px 11px 0px; /* Espaciado interno */
  border-top: 0.1px solid #d9d9d9;
  border-bottom: 0.1px solid #d9d9d9;
  border-left: 0.1px solid #d9d9d9;
  border-right: 0.1px solid #d9d9d9;
  box-sizing: border-box;
  /* Webkit browsers (Chrome, Safari) */
  ::-webkit-scrollbar {
    width: 10px; /* Ancho del scrollbar */
  }

  ::-webkit-scrollbar-track {
    background: #e0e0e0; /* Fondo del track */
    border-radius: 8px; /* Bordes redondeados del track */
  }

  ::-webkit-scrollbar-thumb {
    background: #009688; /* Color del thumb */
    border-radius: 8px; /* Bordes redondeados del thumb */
    border: 2px solid #00796b; /* Borde del thumb */
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #00796b; /* Color del thumb al pasar el ratón */
  }

  /* Firefox */
  scrollbar-width: thin; /* Ancho del scrollbar */
  scrollbar-color: #009688 #e0e0e0; /* Color del thumb y del track */
`;

export const estilosAgregarProducto= (esComputadora) =>({
  //background: "skyblue",
  display: 'grid',
  gridTemplateRows: 'repeat(20, 1fr)',
  gridTemplateColumns: 'repeat(20, 1fr)',
  overflow: "auto",
  boxSizing: "border-box",
  borderRadius: "10px",
  width:"100%"
  //padding: "10px"
});
export const contAgregarEditarProd=(esComputadora)=>({
    //marginTop: "30px",
    display: "grid",
    gridTemplateColumns: "repeat(20,1fr)",
    gridTemplateRows: "repeat(20,1fr)",
    gridArea: esComputadora ? "1/1/21/21":"1/1/21/21",
    backgroundColor: "#EEEEEE",
    //background: esComputadora ? "radial-gradient(circle at 57.52% 55.27%, #fac44d 0, #f2b53c 50%, #eaa62b 100%)":"",
    border: esComputadora ? "1px solid #ccc":"1px solid #ccc",
    borderRadius: esComputadora ? "10px":"10px",
    boxShadow: esComputadora ? '2px 2px 4px rgba(0, 0, 0, 0.2)':"",
    padding: esComputadora ? "30px":"10px",
    boxSizing: "border-box",
    width: "100%",
    height: "93%",
    overflow: "auto",
});
export const tituloStyle=(esComputadora)=>({
  display: "flex",
  justifyContent: "center", /* Centra horizontalmente */
  alignItems: "center", /* Centra verticalmente */
  gridArea:"1/1/2/21",
})
export const categoriaAEProdStyle = (esComputadora)=>({
  gridArea: "2/1/3/21",
  display: "flex",
  justifyContent: "center", /* Centra horizontalmente */
  alignItems: "center", /* Centra verticalmente */
  marginLeft: "5px",
  marginTop: "5px",
  width: esComputadora ? "97%":"97%",
})
export const nombreAEProdStyle = (esComputadora)=>({
  gridArea: "3/1/4/11",
  width: esComputadora ? "97%":"97%",
})
export const unidadPorPaqueteAEStyle=(esComputadora)=>({
  gridArea: "3/11/4/21",
  width: esComputadora ? "97%":"97%",
})
export const precDistribuidorStyle=(esComputadora)=>({
  gridArea: "4/1/5/11",
  width: esComputadora ? "97%":"97%",
})
export const precPersonalStyle=(esComputadora)=>({
  gridArea: "4/11/5/21",
  width: esComputadora ? "97%":"97%",
})
export const precPublicoStyle=(esComputadora)=>({
  gridArea: "5/1/6/11",
  width: esComputadora ? "97%":"97%",
})
export const activoStyle=(esComputadora)=>({
  //backgroundColor:"blue",
  gridArea: "5/11/6/21",
  width: esComputadora ? "95%":"95%",
  marginLeft: "3%",
  marginTop:"3%",
  fontSize: esComputadora ? '11px' : '11px',
  backgroundColor:"white",
  padding:"5px",
  boxSizing:"border-box",
  height:"55px",
  border: esComputadora ? "2px solid #ccc":"2px solid #ccc",
  borderRadius: "5px"
})
export const grupoButtonStyle=(esComputadora)=>({
  backgroundColor:"red",
})
function hexToRGBA (hex, opacidad) {
    hex = hex.replace('#', '')
    const r = parseInt(hex.substring(0, 2), 16)
    const g = parseInt(hex.substring(2, 4), 16)
    const b = parseInt(hex.substring(4, 6), 16)
  
    return `rgba(${r}, ${g}, ${b}, ${opacidad})`
}

