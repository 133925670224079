import React, { useState, useEffect } from 'react'
import ListarPedidos from './Pedidos/ListarPedidos'
import GenerarPedidoDist from './GenerarPedidoDist/GenerarPedidoDist'
import GenerarPedido from './GenerarPedido/GenerarPedido'
import VentaTienda from './VentaTienda/VentaTienda'
import RegistroVentasTienda from './RegistroVentasTienda/RegistroVentasTienda'
import StockProductos from './StockProductos/StockProductos'
import CrearPersona from './InformacionGeneral/Personal/CrearPersona'
import AdministrarProductos from './InformacionGeneral/Productos/AdministrarProductos'
function ContenidoCentral (informacionAdicional) {
  

  const contenedorContenidoCentralstyle = {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    padding: '20px'
  }
  const contenidostyle = {
    display: 'grid',
    gridTemplateRows: 'repeat(20, 1fr)',
    gridTemplateColumns: 'repeat(20, 1fr)',
    width: '100%',
    height: '100%',
    padding: '10px',
    boxSizing: 'border-box',
    border: '1px solid #BBBBBB',
    borderRadius: '10px'
  }
  return (
    <>
      <div
        id='contenedorContenidoCentral'
        style={contenedorContenidoCentralstyle}
      >
        <div id='contenido' style={contenidostyle}>
          {informacionAdicional.opcion=='despachoDePedidos' && <ListarPedidos esComputadora={informacionAdicional.esComputadora}/>}
          {informacionAdicional.opcion=='generarPedidoDistribuidor' && <GenerarPedidoDist esComputadora={informacionAdicional.esComputadora}/>}
          {informacionAdicional.opcion=='generarPedido' && <GenerarPedido esComputadora={informacionAdicional.esComputadora}/>}
          {informacionAdicional.opcion=='ventaTienda' && <VentaTienda esComputadora={informacionAdicional.esComputadora}/>}
          {informacionAdicional.opcion=='registroDeVentas' && <RegistroVentasTienda esComputadora={informacionAdicional.esComputadora}/>}
          {informacionAdicional.opcion=='stockProductos' && <StockProductos esComputadora={informacionAdicional.esComputadora}/>}
          {informacionAdicional.opcion=='crearPersona' && <CrearPersona esComputadora={informacionAdicional.esComputadora}/>}
          {informacionAdicional.opcion=='administrarProductos' && <AdministrarProductos esComputadora={informacionAdicional.esComputadora}/>}

        </div>
      </div>
    </>
  )
}

export default ContenidoCentral
