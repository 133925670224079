/*import React from 'react';
import {Routes,Route,Link} from 'react-router-dom';
import InicioSesion from './../InicioSesion/InicioSesion'
import NotFound from './../NotFound'
import Principal from './../Principal'

class Router extends React.Component{
    render(){
        return(
            <>
                <Routes>
                    <Route path='/' element={<InicioSesion />} />
                    <Route path='/principal' element={<Principal />} />

                    <Route path='*' element={<NotFound />} />
                </Routes>
            </>
        );
    }
}

export default Router;
]*/
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import InicioSesion from './../InicioSesion/InicioSesion';
import NotFound from './../NotFound';
import Principal from './../Principal';

class AppRouter extends React.Component {
    render() {
        return (
            <Routes>
                <Route path='/' element={<InicioSesion />} />
                <Route path='/principal' element={<Principal />} />
                <Route path='*' element={<NotFound />} />
            </Routes>
        );
    }
}

export default AppRouter;


