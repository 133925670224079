import React from 'react'
import './NotFound.css'

function NotFound(){
    return (
        <div id="ContenedorPagina">
            Contenido no encontrado
        </div>
    );
}

export default NotFound;