import React, { useState, useEffect} from 'react';
import './Input.css';

function Input(datosInput) {
  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  useEffect(() => {
    if (datosInput.valorInicial !== null ){
      setIsFocused(true)
      setInputValue(datosInput.valorInicial)
    }else{
      setInputValue("")
    }
  }, []);

  useEffect(() => {
    if (datosInput.valorInicial !== null ){
      setIsFocused(true)
      setInputValue(datosInput.valorInicial)
    }else{

      setInputValue("")
    }  
  }, [datosInput.valorInicial]);

  useEffect(() => {
    if (datosInput.valorInicial !== null ){
      setIsFocused(true)
      setInputValue(datosInput.valorInicial)
    }else{
      setInputValue("")
    }  
  }, [datosInput.estadoCambio]);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = (e) => {
    try{
      setIsFocused(false);
      if(typeof inputValue === "number"){
        setInputValue(inputValue.toString());
      }
      if (inputValue.trim() === '') {
        setIsFocused(false);
      }else{
        if(datosInput.tipo === "number"){
          if (e.target.value.length <= (datosInput.maxDigitos ? datosInput.maxDigitos :4)) {
            datosInput.onBlur(e);
            setInputValue(e.target.value);
            setIsChecked(e.target.checked);
            if (!isChecked) {
              setIsFocused(false);
            }
          }
        }else{
          datosInput.onBlur(e);
            setInputValue(e.target.value);
            setIsChecked(e.target.checked);
            if (!isChecked) {
              setIsFocused(false);
            }
        }
      }
    }
    catch(error){
    }
  };

  const handleChange = (e) => {
    if(datosInput.tipo === "number"){
      if (e.target.value.length <= (datosInput.maxDigitos ? datosInput.maxDigitos :4)) {
        datosInput.onChange(e);
        setInputValue(e.target.value);
        setIsChecked(e.target.checked);
        if (!isChecked) {
          setIsFocused(false);
        }
      }
    }else{
      datosInput.onChange(e);
        setInputValue(e.target.value);
        setIsChecked(e.target.checked);
        if (!isChecked) {
          setIsFocused(false);
        }
    }
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const listaValores = ['checkbox', 'radio', 'file', 'submit', 'button'];

  return (
    <div className='input-container'>
      {listaValores.includes(datosInput.tipo) ? (
        <div>
          <input
            type={datosInput.tipo ? datosInput.tipo : ""}
            id={datosInput.id ? datosInput.id:""}
            placeholder={datosInput.antiguo ? datosInput.antiguo:""}
            style={datosInput.estilos ? datosInput.estilos:{}}
            disabled={datosInput.deshabilitado ? true: false}
          />
          {datosInput.moderno !== '' && (
            <label
              id = "notexto"
              htmlFor='inputField'
              className={`input-label ${
                isFocused || inputValue.trim() !== '' ? 'active' : ''
              }`}
            >
              {datosInput.moderno}
            </label>
          )}
        </div>
      ) : (
        <div style={{ position: 'relative' }}>
          <input
            type={datosInput.tipo}
            id={datosInput.id}
            className= {datosInput.tipo==='date' ? inputValue ==="" ? 'input-field':'input-field-date':'input-field'}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={datosInput.antiguo ? datosInput.antiguo: ""}
            style={datosInput.estilos}
            disabled={datosInput.deshabilitado ? true: false}
            value = {datosInput.valorInicial !==0 ? inputValue: ""}
            autoComplete= {datosInput.autoComplete ? "current-password":""}
            // Si el tipo de entrada es 'password' y se activa la visualización de la contraseña, cambia el tipo a 'text'
            {...(datosInput.tipo === 'password' && isPasswordVisible && { type: 'text' })}
          />
          {/* Ícono para mostrar u ocultar la contraseña */}
          {datosInput.tipo === 'password' && (
            <div
              className='password-toggle'
              onClick={togglePasswordVisibility}
              style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}
            >
              {isPasswordVisible ? '👁️' : '🔒'}
            </div>
          )}
          {datosInput.moderno !== '' && (
            <label
              htmlFor='inputField'
              className={`${datosInput.tipo==='date' ? 'input-label date':'input-label'} ${
                isFocused || (typeof inputValue === 'string' ? inputValue.trim():'') !== '' ? 'active' : ''
              }`}
              style={datosInput.estiloLabel !== null ? datosInput.estiloLabel:{}}
            >
              {datosInput.moderno}
            </label>
          )}
        </div>
      )}
    </div>
  );
}

export default Input;
